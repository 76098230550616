import React from 'react';
import { map } from 'ramda';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import moment from 'moment';

const ArticleLargeItem = ({ article: { title, image, date, path, categories } }) => {
    const { t } = useTranslation('blog');

    return (
        <div className="container max-w-3xl">
            <Link to={path}>
                <div className="w-full pb-6 rounded-xl bg-white shadow-md">
                    <GatsbyImage
                        image={image}
                        alt={title}
                        className="max-h-96 w-full object-cover rounded-t-xl"
                    />
                    <div className="rounded-xl bg-white px-4 pt-2">
                        <h2 className="w-full my-2 text-xl font-bold leading-tight text-left text-black mb-2 line-clamp-2">
                            {title}
                        </h2>
                        <div className="text-xs text-gray-400">{moment(date).format('LL')}</div>
                        <div className="pt-2">
                            {map(
                                (category) => (
                                    <Link
                                        to={`/${category}`}
                                        className="text-sm inline-block text-black bg-sapin-200 m-1 px-2 py-1 rounded hover:text-white"
                                    >
                                        {t(`category.${category}`)}
                                    </Link>
                                ),
                                categories
                            )}
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    );
};

export default ArticleLargeItem;
